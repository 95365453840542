import React from "react"
import News from "../../blocks/news"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "glushkov_mom" }) {
    ...NewsInfo
    innerImages {
      maksimov {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Клиент Catenaccio Sports Agency <Link to="/players/glushkov">Никита Глушков</Link> стал главным героем матча «Балтика» — «Локомотив», где он «на подъеме» отыграл всю игру и выполнил решающий удар в серии пенальти. </p>
          <p>В «Спорт день за днем» вышло большое <a href="https://www.sportsdaily.ru/articles/glavnyj-geroj-matcha-baltika-lokomotiv-schitaet-chto-zenitu-ne-nuzhny-ego-sovety">интервью Никиты</a> о матче и карьере футболиста.</p>
        </div>
      </div>
    )
  }
}